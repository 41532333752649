import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { Company } from 'src/app/models/company.model';
import { CreateScreeningDto } from 'src/app/models/dtos.model';
import { Screening } from 'src/app/models/screening.model';
import { User } from 'src/app/models/user.model';
import { CompanyService } from 'src/app/services/company.service';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { ScreeningService } from 'src/app/services/screening.service';
import { UserService } from 'src/app/services/user.service';
import { ContactService } from 'src/app/services/contact.service';
import { CompanyFoldersService } from '../../services/company-folders.service';
import { QuantifyService } from 'src/app/services/quantify.service';
import { ValidationService } from 'src/app/services/validation.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
  selector: 'app-screening-overview',
  templateUrl: './screening-overview.component.html',
  styleUrls: ['./screening-overview.component.scss']
})
export class ScreeningOverviewComponent implements OnInit {
  public showResetView: boolean = false;
  public started=false;
  public companyId: number;
  public flowCredits : number;
  public validationHours : number;
  public engineerhours : number;
  public selectedScreening: Screening;
  public selectedShared: any;
  public foldertorestore: any;
  public hasAccess: boolean = false;
  public showLoad: boolean = false;
  public hideDuplicateicon: boolean = true;
  public currentUser: User;
  public drafts: Screening[];
  public filteredDrafts: Screening[];
  public completed: Screening[];
  public filteredCompleted: Screening[];
  public screenings: Screening[];
  public allScreenings: Screening[];
  public notifications: any[];
  public user_role;
  public showStartModal = true;
  public showEditFolderModal=false;
  public showReflowLoading=true;
  public canShowRefillModal=false;
  public loading = false;
  public canShowFolders = false;
  public shareModal = false;
  public showDropdown = true;
  public deleteShareModal = false;
  public deleteModal = false;
  public infoShareModal = false;
  public importModal = false;
  public restoreModal = false;
  public notAllowedModal = false;
  public stopShareModal = false;
  public compareModal = false;
  public refillModal = false;
  public validationModal = false;
  public engineeringAssistanceModal = false;
  public calculationModal = false;
  public requestValidationModal = false;
  public importLink = "";
  public company: Company;
  public sharedScreenings: any[];
  public scrollValue = 0;
  public archiveModal = false;
  public archiveOperation='';
  public archivingTarget='';

  public searchKeyWord:string="";
  public focusSearchKeyWord:boolean=false;
  public searchList:any[]=[];
  public recentScreenings:any[]=[];
  public selectedFolder="";
  public selectedFolderId;
  public selectedProject:any=null;
  public todisplay:any[]=[];
  public archives:any[]=[];
  public folderToArchive=null;

  public draftFolders:any[]=[];
  public completedFolders:any[]=[];
  public archivedFolders:any[]=[];
  public targetFolder=null;
  public selectedSubFolder=null;
  public targetRefillAdmin=false;
  public canDisplayRename:boolean=false;
  public modalType="upsert-folder"
  public projectInfo=[
    {stateValue:false,state:"screening-setup",name:"Project Setup"},
    {stateValue:false,state:"system-definition",name:"System Definition"},
    {stateValue:false,state:"screening-inventory",name:"Product Inventory"},
    {stateValue:false,state:"review",name:"Project Review"},
  ];
  public latestUpdate:any;

  public shooldChangeInfo=true;
  public updatesModal: string;
  public moveFolderExpanded=false;
  public currentExpandFolder="";
  public expandables:any=[];
  public showAgain=false;
  public showTerms=false;
  public showMoveMenu=false;
  public selectedTargetFolder:any=null;
  public showMoveButton=false;
  public showMoveProjectModal=false;
  public locals;
  /***
   * notification variables
   */
  public projectHaveNotifications:boolean=false;
  public projectNotifications:Array<any>=[];
  public projectRequest:any;
  public sendResourceRequestDto:any = {userId: 0, requestType:"important"};;
  public requestStatus:String="";
  public statusIcon:String="";
  public sharingCode:String="";

  public draftLevel=0;
  public hasChildrensDraft={}
  public hasChildrensCompleted={}
  public hasChildrensArchived={}
  public visitedDraft={}
  public visitedArchive={}
  public visitedCompleted={}
  public toggledsubfolder={}
  public archiveBuffer:any[]=[];

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private notificationService: NotificationServiceService,
    private toasterService: ToastrService,
    private screeningService: ScreeningService,
    private userService: UserService,
    private contactService:ContactService,
    private companyFoldersService:CompanyFoldersService,
    private quantifyService:QuantifyService,
    private validationService:ValidationService,
    private dbService: NgxIndexedDBService
  ) {
    //this.showLatesUpdates();
   }
  public closeRenameModal(event){
    this.canDisplayRename=false;
    if(event!=null){
      this.showScreenings();
    }
  }
  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe(user=>{
      this.companyId = Number(user.companyId);
      this.getReflowPoints();
      this.userService.getUserById(Number(user.userId)).subscribe(res => {
        this.currentUser = res;
        if (this.currentUser.access.filter(x => x.access == "Gaia Project").length > 0) {
          this.hasAccess = true;
          this.showScreenings();
          this.showImportedScreenings();
        }
      });
    });
    
  }

  onClose(): void {
    this.compareModal = false;
  }

  onCloserefill(): void {
    this.refillModal = false;
  }

  public getReflowPoints():void{
    this.userService.getCurrentUser().subscribe(user=>{
      this.companyService.retrieveCustomerCompany(Number(user.companyId)).subscribe(resCompany => {
        this.company = resCompany;
        this.flowCredits =this.company.reflowPoints
        this.validationHours = this.company.validations ;
        this.engineerhours = this.company.engineeringHours ;

      });
    });
  }
  public showScreenings(): void {
    this.canShowFolders=false;
    this.dbService.getAll('Screenings').subscribe((indexedDBResut:Array<Screening[]>) => {
          if(indexedDBResut.length>0)
          {
            this.screenings=indexedDBResut[0];
            this.allScreenings=indexedDBResut[0];
            this.archives=this.screenings.filter(x=>x?.archived==true);
            this.screenings=this.screenings.filter(x=>x?.archived==false);
            this.completed = this.screenings.filter(screening => screening.finished == true);
            this.drafts = this.screenings.filter(screening => screening.finished == false);
            this.todisplay=this.drafts;
            if(this.selectedFolder=="completed"){
              this.todisplay=this.completed;
            }
            this.recentScreenings=indexedDBResut[0].sort((a, b) => (a.id < b.id) ? 1 : -1);
            this.recentScreenings=this.recentScreenings.slice(0,6);
            this.dbService.getAll('Folders').subscribe((ans:Array<any[]>)=>{  
                this.archivedFolders=ans[0].filter(x=>x.archived==true);
                //ans=ans.filter(x=>x.archived==false);
                this.locals=ans[0];
                this.rearrangeFolders();
              });
          }
          else{
            this.screeningService.getScreeningByCompanyId(this.companyId).subscribe(res => {
              this.dbService.add('Screenings', res).subscribe(
                () => {
                    // Do something after the value was added
                },
                error => {
                    console.log(error);
                }
              );
              this.screenings = res;
              this.allScreenings = res;
              this.archives=this.screenings.filter(x=>x?.archived==true);
              this.screenings=this.screenings.filter(x=>x?.archived==false);
              this.completed = this.screenings.filter(screening => screening.finished == true);
              this.drafts = this.screenings.filter(screening => screening.finished == false);
              this.todisplay=this.drafts;
              if(this.selectedFolder=="completed"){
                this.todisplay=this.completed;
              }
              this.recentScreenings=res.sort((a, b) => (a.id < b.id) ? 1 : -1);
              this.recentScreenings=this.recentScreenings.slice(0,6);
              this.companyFoldersService.getFoldersOfCompany(this.companyId).subscribe(ans=>{
                this.dbService.add('Folders', ans).subscribe(
                  () => {
                      // Do something after the value was added
                  },
                  error => {
                      console.log(error);
                  }
                );
                this.archivedFolders=ans.filter(x=>x.archived==true);
                //ans=ans.filter(x=>x.archived==false);
                this.locals=ans;
                this.rearrangeFolders();
              });
            });
            console.log(this.selectedFolder,this.selectedSubFolder);
          }
      },
      error => {
          console.log(error);
      }
    );
    
  }

  public rearrangeFolders(){
    let ans=this.locals;
    this.draftLevel=0;
    this.hasChildrensDraft={}
    this.hasChildrensCompleted={}
    this.hasChildrensArchived={}
    this.visitedDraft={}
    this.visitedArchive={}
    this.visitedCompleted={}
    this.toggledsubfolder={}
    this.archiveBuffer=[]
    this.draftFolders=ans.filter(x=>x.isCompleted==false && x.archived==false);
    this.draftFolders.forEach(element => {
      if(this.hasChildrensDraft[element?.parentId]==null||this.hasChildrensDraft[element?.parentId]==[]){
        this.hasChildrensDraft[element?.parentId]=[];
      }
      this.hasChildrensDraft[element?.parentId].push(element)
    });
    this.visitedDraft[0]=true;
    
    this.completedFolders=ans.filter(x=>x.isCompleted==true && x.archived==false );
    this.completedFolders.forEach(element => {
      if(this.hasChildrensCompleted[element?.parentId]==null||this.hasChildrensCompleted[element?.parentId]==[]){
        this.hasChildrensCompleted[element?.parentId]=[];
      }
      this.hasChildrensCompleted[element?.parentId].push(element)
    });
    this.visitedCompleted[0]=true;
    ans.forEach(element => {
      if((element?.isArchiveMainFolder==true || element?.parentId==0 ) && element.archived==true){
        this.hasChildrensArchived[element?.id]=[];
        this.archiveBuffer.push(this.archivedFolders.filter(x=>x.id==element?.id)[0]);
        this.hasChildrensArchived[element?.id]=this.hasChildrensArchived[element?.id].concat(ans.filter(x=>x.parentId==element.id));
      }
      else{
        if(ans.filter(x=>x.parentId==element.id).length>0){
          this.hasChildrensArchived[element?.id]=[];
          this.hasChildrensArchived[element?.id]=this.hasChildrensArchived[element?.id].concat(ans.filter(x=>x.parentId==element.id));
        }
      }
    });
    this.archiveBuffer=this.archiveBuffer.filter(x=>x!=null&&x!=undefined)
    let cntBuffer=this.archiveBuffer.length;
    while(cntBuffer>0){
      let temp=this.archiveBuffer[0];
      if(this.archiveBuffer.filter(x=>x.id==temp.parentId)[0]==undefined){
        this.archiveBuffer.push(temp);
      }
      this.archiveBuffer.shift();
      cntBuffer--;
    }
    this.canShowFolders=true;
  }
  public showResetModal(isShow: boolean) {
    this.showResetView = isShow;
  }

  public showImportedScreenings(): void {
    this.screeningService.getSharedScreeningByCompanyId(this.companyId).subscribe(res => {
      this.sharedScreenings = res;
    });
  }

  public createScreening(): void {
    let createScreeningDto: CreateScreeningDto = { companyId: this.companyId, folderId: this.selectedFolderId };
    this.screeningService.createEmptyScreening(createScreeningDto).subscribe((addedScreening:Screening) => {
      this.allScreenings.push(addedScreening);
      this.dbService.clear('Screenings').subscribe(res=>{
        this.dbService.add('Screenings', this.allScreenings).subscribe(ans=>{
          this.selectedScreening = addedScreening;
          let screeningLog = { date: new Date(), description: "Initializing the screening", screeningId: addedScreening.id, userName: this.currentUser.fullName };
          this.screeningService.createScreeningLog(screeningLog).subscribe(resLog => {
          });
          this.router.navigate([
            "/homeServiceProvider",
            this.companyId,
            "screening",
            addedScreening.id
          ]);
        });
      });
    });
  }

  public selectProject(item){
    if (this.selectedFolder=="sharedFolder"){
      this.selectedShared = item;
      this.infoShareModal = true;
    }
    else{
      this.screeningService.getScreeningById(item.id).subscribe(res=>{
      this.selectedProject=res;
      this.getValidationHistory();
      this.projectInfo.map(x=>x.stateValue=false);
      this.projectInfo.forEach(element => {
        if(element.state==res.state){
          this.shooldChangeInfo=false
        }
        if(this.shooldChangeInfo){
          this.projectInfo[this.projectInfo.indexOf(element)].stateValue=true;
        }
      });
      this.shooldChangeInfo=true;
      //prepare content for dropdown
      //display all folders except original container of this project
      this.expandables=[...this.draftFolders,...this.completedFolders];

      this.currentExpandFolder=this.selectedFolder;
     
      if(this.selectedFolder=="draft"){
        this.expandables=this.draftFolders;
      }else if(this.selectedFolder=="completed"){
        this.expandables=this.completedFolders;
      }
      });
    }
  }

  public moveProjet(event){
    this.showMoveProjectModal=!this.showMoveProjectModal;
    
    if(event==true){
      let targetProject=this.selectedProject;
      let id=this.selectedProject.id;

      this.allScreenings=this.allScreenings.filter(x=>x.id!=id)
      
      targetProject.companyFolderId=this.selectedTargetFolder.id;
      this.allScreenings.unshift(targetProject);
      
      this.dbService.clear('Screenings').subscribe((status) => {
        this.dbService.add('Screenings', this.allScreenings).subscribe((key) => {
            
            this.dismissMoveFolder();
            this.showScreenings();
          });
      });
    }
    
  }
  public dismissMoveFolder(){
    this.showMoveMenu=false;
    this.showMoveButton=false;
    this.selectedTargetFolder=null;
  }

  public selectDraftSubFolder(folder):void{
    if(this.showMoveMenu==true){
      this.selectedTargetFolder=folder;
      this.showMoveButton=true;
      return
    }
    this.selectedFolder='draft';
    this.selectedFolderId=folder.id;
    this.todisplay = this.drafts.filter(x=>x.companyFolderId == folder.id);
  }

  public selectCompletedSubFolder(folder):void{
    if(this.showMoveMenu==true){
      this.selectedTargetFolder=folder;
      this.showMoveButton=true;
      return
    }
    this.selectedFolder='completed';
    this.todisplay = this.completed.filter(x=>x.companyFolderId == folder.id);
    this.showMoveButton=true;
  }

  public selectArchivedSubFolder(folder):void{
    if(this.showMoveMenu==true){
      this.selectedTargetFolder=folder;
      return
    }
    this.selectedFolder='archived';
    this.todisplay = this.archives.filter(x=>x.companyFolderId == folder.id);
  }

  public editSceening(screening: Screening): void {
    this.selectedScreening = screening;
    this.router.navigate([
      "homeServiceProvider",
      this.companyId,
      "screening",
      screening.id
    ]);
  }

  public deleteScreening(screeningId: number): void {
    this.loading = true;
    this.screeningService.deleteScreening(screeningId).subscribe(res => {
      this.showScreenings();
      this.loading = false;
    });
  }

  public copyScreening(screeningId: number): void {
    this.hideDuplicateicon = false;
    this.screeningService.copyScreening(screeningId).subscribe(res => {
      this.allScreenings.push(res);
      this.dbService.clear('Screenings').subscribe(deletedScreenings=>{
        this.dbService.add('Screenings', this.allScreenings).subscribe(ans=>{
          this.editSceening(res);
          this.hideDuplicateicon =true;
        });
      });
      
    });
  }

  public closeModal(event) {
    this.showStartModal = false;
  }
  public closeEditFolderModal(event){

    if(event!=null){
      if(this.modalType=='rename-folder'){
        const index = this.locals.indexOf(this.targetFolder);
        if (index > -1) {
          this.locals.splice(index, 1); 
        }
      }
      
      this.locals.unshift(event);
      
      this.dbService.clear('Folders').subscribe((status) => {
        this.dbService.add('Folders', this.locals)
          .subscribe((key) => {
          });
        this.showScreenings();
      });
    }
    this.showEditFolderModal = false;
  }
  public closeCompareModal(event) {
    this.compareModal = false;
    if(event!=null){
      localStorage.setItem('compare',JSON.stringify(event.map(project => project.id)));
      this.router.navigate([
        "/homeServiceProvider",
        Number(localStorage.getItem('company_id')),
        "compare",
      ]);
    }
  }


  public copyLink(): void {
    this.shareModal = false;
    this.toasterService.success(this.selectedScreening.reflowId, "Copied");
    
  }

  public showShareModal(screening): void {
    this.screeningService.activateShare(screening.reflowId).subscribe(res=>{
      this.selectedScreening = screening;
      this.shareModal = true;
      this.sharingCode=res.sharingCode;
    });
  }

  public showImportModal(): void {
    this.importModal = true;
  }
  public showValidationModal(): void {
    this.validationModal = true;
  }
  public showEngineeringAssistanceModal(): void {
    this.engineeringAssistanceModal = true;
  }
  public showCalculationModal(): void {
    this.calculationModal = true;
  }
  public showRequestValidationModal(): void {
    this.requestValidationModal = true;
  }
  public showRestoreModal(folder): void {
    this.foldertorestore = folder
    this.restoreModal = true;
  }

  public hideShareModal(): void {
    this.shareModal = false;
  }

  public hideImportModal(): void {
    this.importModal = false;
  }
  public hideValidationModal(): void {
    this.validationModal = false;
  }
  public hideEngineeringAssistanceModal(): void {
    this.sendResourceRequestDto.userId = this.currentUser.id;
    this.sendResourceRequestDto.requestType = "Engineering assistance hours";
    this.engineeringAssistanceModal = false;
    this.companyService.sendResourcesRequest(this.sendResourceRequestDto).subscribe(res =>{
    })
    this.validationModal = true
  }
  public closeEngineeringModal(){
    this.engineeringAssistanceModal = false;
  }

  public hideCalculationModal(): void {
    this.sendResourceRequestDto.userId = this.currentUser.id;
    this.sendResourceRequestDto.requestType = "Calculations"
    this.calculationModal = false;
    this.companyService.sendResourcesRequest(this.sendResourceRequestDto).subscribe(res =>{

    })
    this.validationModal = true
  }
  public closeCalculationmodal(){
    this.calculationModal = false;
  }
  public hideRequestValidationModal(): void {
    this.sendResourceRequestDto.userId = this.currentUser.id;
    this.sendResourceRequestDto.requestType = "Validations";
    this.requestValidationModal = false;
    this.companyService.sendResourcesRequest(this.sendResourceRequestDto).subscribe(res =>{

    })
    this.validationModal = true
  }
  public closeValidationModal(){
    this.requestValidationModal = false;
  }
  public hideRestoreModal(): void {
    this.restoreModal = false;
  }
  public hideNotAllowedModal(): void {
    this.notAllowedModal = false;
  }
  public hideUpdatesModal() : void{
     if(this.showAgain==true){
      this.notificationService.checkUpdate({userId:Number(localStorage.getItem("user_id")),checkedUpdates:true}).subscribe(res=>{
        this.updatesModal = '';
       });
     }
     else{
      this.updatesModal = '';
     }
  }

  public importScreening(): void {
    let importDto = { companyId: this.companyId, reflowId: this.importLink };
    this.screeningService.addSharedScreening(importDto).subscribe(res => {
      if (res.id != 0) {
        this.importModal = false;
        this.toasterService.success(this.importLink, "Imported Successfully");
        this.showImportedScreenings();
        this.showScreenings();
      }
      else {
        this.toasterService.error("Wrong reflow code please contact the sharing company to have the correct one", "Not Found");
      }
    });
  }

  public scrollDown(event): any {
    this.scrollValue = this.scrollValue + 95;
    event.scrollTo(0, this.scrollValue);
  }

  public scrollUp(event): any {
    this.scrollValue = this.scrollValue - 95
    event.scrollTo(0, this.scrollValue);
  }

  public showDeleteShareModal(screening): void {
    this.deleteShareModal = true;
    this.selectedShared = screening;
  }

  public hideDeleteShareModal(): void {
    this.deleteShareModal = false;
  }

  public deleteShared(): void {
    this.screeningService.deleteSharedScreening(this.selectedShared.id).subscribe(res => {
      this.showImportedScreenings();
      this.deleteShareModal = false;
    });
  }

  public showInfoModal(screening): void {
    this.selectedShared = screening;
    this.infoShareModal = true;
  }

  public hideInfoShareModal(): void {
    this.infoShareModal = false;
  }

  public hideDeleteModal():void{
    this.deleteModal = false;
  }

  public showDeleteModal(screening): void {
    this.deleteModal = true;
    this.selectedScreening = screening;
  }

  public showCompareModal(isShow: boolean) {
    this.compareModal = isShow;
  }
  public showRefillModal() {
    this.user_role = localStorage.getItem("user_role");
    this.targetRefillAdmin=this.user_role=='Master Admin'
    this.canShowRefillModal=true;
  }

  public closeRefillModal(event) {
    this.canShowRefillModal = false;
  }

  public showNotAllowedModal(event) {
    this.notAllowedModal = true;
  }

  public deleteCompleted():void{
    this.screeningService.deleteScreening(this.selectedProject.id).subscribe(res => {
      this.todisplay=this.todisplay.filter(x=>x.id != this.selectedProject.id);
      this.drafts=this.drafts.filter(x=>x.id != this.selectedProject.id);
      this.completed=this.completed.filter(x=>x.id != this.selectedProject.id);
      this.selectedProject=null;

      this.deleteModal = false;
    });
  }

  public stopSharing():void{
    this.screeningService.stopSharingScreening(this.selectedShared.id).subscribe(res => {
      this.showScreenings();
      this.showImportedScreenings();
      this.stopShareModal = false;
    });
  }

  public showStopShareModal(screening):void{
    this.selectedShared = screening;
    this.stopShareModal = true;
  }
  public hideStopShareModal():void{
    this.stopShareModal = false;
  }

  public chatStarted=false;
  public toggleChat(event){
    this.chatStarted=event
  }

  public downloadSheet(screeningId:number,screeningName,event):void{
    event.hidden=true;
    this.showDropdown=false;
    this.screeningService.getExcelResult(screeningId,screeningName).subscribe(blob => {
      this.screeningService.DownloadFile(blob,screeningName+".xlsx", 'application/pdf');
      this.showDropdown=true;
    });
  }

  public downloadPdf(screeningId:number,screeningName,event):void{
    event.hidden=true;
    this.loading=true;
    this.showDropdown=false;
    this.screeningService.getProofOfAssessment(screeningId,screeningName).subscribe(blob => {
      this.screeningService.DownloadFile(blob, screeningName+".pdf", 'application/pdf');
      this.loading=false;
      this.showDropdown=true;
    });
  }

  public processBlur(){
    this.focusSearchKeyWord=false
  }

  public makeSearch(){
    this.searchList=this.screenings.filter(x=>x.name.toLowerCase().includes(this.searchKeyWord.toLowerCase())).splice(0,6)
  }

  public selectFromSearch(ans){
    this.searchKeyWord=ans.name;
  }

  public toogleMessage():void{
    this.showAgain=!this.showAgain;
  }

  public getValidationHistory(){
    this.validationService.hasValidationRequest(this.selectedProject.id).subscribe(res=>{
      this.projectHaveNotifications=res.hasRequest;
      this.projectRequest=res.request;
      if(this.projectRequest.status=="Received"){
        this.requestStatus="Validation request received";
        this.statusIcon="icon-validation validation";
      }
      else if(this.projectRequest.status=="in progress"){
        this.requestStatus="Validating";
        this.statusIcon="icon-in-progress orange";
      }
      else if(this.projectRequest.status=="corrected"){
        this.requestStatus="Awaiting correction";
        this.statusIcon="icon-edit purple";
      }
      else if(this.projectRequest.status=="completed"){
        this.requestStatus="Validated";
        this.statusIcon="icon-validation-completed blue";
      }
      if(res.hasRequest==true){
        this.validationService.getHistory(this.selectedProject.id).subscribe(history=>{
          this.projectNotifications=history;
        });
      }
    });
  }

  public downloadCertificate():void{
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.projectRequest.validationCertificate);
    link.setAttribute('download', this.selectedProject.name+`.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
 
  public archiveFolder(folder){
    
      this.companyFoldersService.archiveFolder(folder.id).subscribe(ans=>{
        //remove this folder from the list of folders
        const index = this.locals.indexOf(folder);
        if (index > -1) {
          this.locals.splice(index, 1); 
        }

        //update folder with his new attributes
        folder.archived=true;
        folder.isArchiveMainFolder=true;

        //add folder to the existing list of folders; to be patched into indexdb
        this.locals.unshift(folder);
        
        this.dbService.clear('Folders').subscribe((status) => {
          this.dbService.add('Folders', this.locals)
            .subscribe((key) => {
            });
            this.toasterService.success('Folder archived !', "Success");
            this.hideArchiveModal();
          this.showScreenings();
        });
    },err=>{
    })
  }
  public restoreFolder(folder){
    this.companyFoldersService.restoreFolder(folder.id).subscribe(ans=>{
      //remove this folder from the list of folders
      const index = this.locals.indexOf(folder);
      if (index > -1) {
        this.locals.splice(index, 1); 
      }

      //update folder with his new attributes
      folder.archived=false;
      folder.isArchiveMainFolder=false;

      //add folder to the existing list of folders; to be patched into indexdb
      this.locals.unshift(folder);
      
      this.dbService.clear('Folders').subscribe((status) => {
        this.dbService.add('Folders', this.locals)
          .subscribe((key) => {
          });
          this.toasterService.success('Folder restored !', "Success");
          this.hideArchiveModal();
        this.showScreenings();
      });
    },err=>{
    })
  }

  public hideArchiveModal()
  {
    this.archiveModal = false;
  }
  public showArchiveModal(operation,target)
  {
    this.archiveOperation=operation;
    this.archivingTarget=target;
    this.archiveModal = true;
  }

  public archiveScreening(project){
    project.archived=true;
    this.screeningService.archiveScreening(project.id).subscribe(ans=>{
      this.dbService.getAll('Screenings').subscribe((indexedDBResut:Array<Screening[]>) => {
        let screenings = indexedDBResut[0];
        screenings[screenings.findIndex(x=>x.id==project.id)]=project;
        this.dbService.clear('Screenings').subscribe(deleteResult=>{
          this.dbService.add('Screenings', screenings).subscribe(addResult=>{
            this.showScreenings();
            this.showScreenings();
            this.toasterService.success('Project archived !', "Success");
            this.hideArchiveModal();
          });
        });
      });
      
    },err=>{
    })
  }
  public restoreSceening(project){
    project.archived=false;
    this.screeningService.restoreScreening(project.id).subscribe(ans=>{
      this.dbService.getAll('Screenings').subscribe((indexedDBResut:Array<Screening[]>) => {
        let screenings = indexedDBResut[0];
        screenings[screenings.findIndex(x=>x.id==project.id)]=project;
        this.dbService.clear('Screenings').subscribe(deleteResult=>{
          this.dbService.add('Screenings', screenings).subscribe(addResult=>{
            this.showScreenings();
            this.showScreenings();
            this.toasterService.success('Project restored !', "Success");
            this.hideArchiveModal();
          });
        });
      });
      
    },err=>{
    })
  }

  public showArchiveBuffer():void{
    
  }

  public archiveModalAction(){
    if(this.archiveOperation=='archive'){
      if(this.archivingTarget=='project'){
        this.archiveScreening(this.selectedProject)
      }else{
        this.archiveFolder(this.folderToArchive)
      }
    }else{
      if(this.archivingTarget=='project'){
        this.restoreSceening(this.selectedProject)
      }else{
        this.restoreFolder(this.folderToArchive)
      }
    }
  }
}

<div class="final-step" id="final-step">
    <div class="row">
        <div class="reflow-logo">
            <img src="../../assets/landing-page/Reflow logo W.svg" alt="" class="logo">
        </div>
        <div class="steps">
            <label class="step">step<br>3</label>
        </div>
    </div>
    <div class="contents">
        <h1 class="big-title-white">Environmental quantification made easy</h1>
        <label class="green-description">Explore the first worldwide self-service LCA-based digital platform</label>
        <br>


        <div class="cards-container">
            <div class="plan-card-start" (click)="selectPlan('plan-card-start')" [ngClass]="{'selected-plan': selected == 'plan-card-start' }">
                <div class="plan-card-heading">
                    <p class="plan-card-heading-text">Start-up</p>
                </div>
                <p class="plan-card-description">Up to <br>2 <br>Calculations per month</p>
                <p class="plan-card-description-2">Perfect for Start-ups that wants to get started with environmental quantification</p>
                <p class="plan-card-credit">1 500 Kr./month</p>
                <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="Start-up-DKK-Monthly" data-cb-item-0-quantity="1">
                    <button class="credit-button" (click)="updateUserData('Start-up')">Get Started</button>
                </a>
                <p class="plan-card-note" style="font-size: 12px;">None commitment <br> Note: Buy-ups unable</p>
            </div>
            <!--------------------------end card item------------------------------------------>

            <!--------------------------start card item------------------------------------------>
            <div class="plan-card-basic" (click)="selectPlan('plan-card-basic')" [ngClass]="{'selected-plan': selected == 'plan-card-basic' }">
                <div class="plan-card-heading">
                    <p class="plan-card-heading-text">Basic</p>
                </div>
                <p class="plan-card-description">Up to <br>8 <br>Calculations per month</p>
                <p class="plan-card-description-2">Great for small and medium-sized enterprises to start quantifying environmental performance</p>
                <p class="plan-card-credit">3 500 Kr./month</p>
                <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="Basic-DKK-Monthly" data-cb-item-0-quantity="1">
                    <button class="credit-button" (click)="updateUserData('Basic')">Get Started</button></a>
                <p class="plan-card-note">Commitment for 6 months</p>
            </div>
            <!--------------------------end card item------------------------------------------>
            <!---------------------start best seller card----------------------------------------------->
            <div class="plan-card-advanced" (click)="selectPlan('plan-card-advanced')" [ngClass]="{'selected-plan': selected == 'plan-card-advanced' }">
                <div class="plan-card-heading">
                    <p class="plan-card-heading-text">Advanced</p>
                </div>
                <p class="star-icon">

                    <svg xmlns="http://www.w3.org/2000/svg" width="70px" height="69" fill="#FFAE51" class="bi bi-star-fill" viewBox="0 0 16 16">
    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
    </svg></p>
                <p class="plan-card-best-seller">Best seller</p>
                <p class="plan-card-description">Up to <br>16 <br>Calculations per month</p>
                <p class="plan-card-description-2">Best choice for enterprises that wants to accelerate the documentation and transparency of their environmental performance</p>
                <p class="plan-card-credit">5 500 Kr./month</p>
                <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="Advanced-DKK-Monthly" data-cb-item-0-quantity="1">
                    <button class="credit-button" (click)="updateUserData('Best seller')">Get Started</button></a>
                <p class="plan-card-note">Commitment for 12 months</p>
            </div>
            <!--------------------------start card item------------------------------------------>
            <div class="plan-card-entreprise" (click)="selectPlan('plan-card-entreprise')" [ngClass]="{'selected-plan': selected == 'plan-card-entreprise' }">
                <div class="plan-card-heading">
                    <p class="plan-card-heading-text">Entreprise</p>
                </div>
                <p class="plan-card-description">Up to <br>24 <br>Calculations per month</p>
                <p class="plan-card-description-2">The ideal choice for enterprises that has fully committed to the green transition and wants to utilize the full potential of their environmental improvements</p>
                <p class="plan-card-credit">7 000 Kr./month</p>
                <a href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="Enterprise-DKK-Monthly" data-cb-item-0-quantity="1">
                    <button class="credit-button" (click)="updateUserData('Entreprise')">Get Started</button></a>
                <p class="plan-card-note">Commitment for 12 months</p>
            </div>
            <!--------------------------end card item------------------------------------------>
        </div>

        <div class="row go-back">
            <button class="goback-btn" (click)="goToTarget('signup')"><span>BACK</span></button>
        </div>
    </div>


</div>

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent implements OnInit,OnChanges {
  @Output() goToComponent= new EventEmitter();
  @ViewChild("captchaElem") captchaElem: ReCaptcha2Component;
  @ViewChild("langInput") langInput: ElementRef;
  public startedSteps=false;
  public signupValues=null;
  public submitted:boolean=false;
  public currentStep=1;
  public emailExists: boolean = false;
  public existingMail: string = "";
  public siteKey = "6Lez6NMZAAAAAMD7ZBqIH-0Zj4fHvqNkwFy7th83";
  public theme: "light" | "dark" = "light";
  public size: "compact" | "normal" = "normal";
  public lang = "en";
  public type: "image" | "audio";
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public isVerified = true;
  public loading = false;
  public verificationFailed = false;
  public loginResult: any;
  public captchaResponse?: string;
  public signupForm;
  public current_year = new Date().getFullYear();

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private userService:UserService
  ) { }

  ngOnInit(): void {
    this.signupForm = this.fb.group({
      //companyName: ["", Validators.required],
      fullName: ["", Validators.required],
      password: ["", Validators.required],
      repeatPassword: ["", Validators.required],
      workEmail: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      recaptcha: [""],
      companyname: ["", Validators.required],
      industry: ["", Validators.required],
      address: ["", Validators.required],
      postalcode: ["", Validators.required],
      city: ["", Validators.required],
      country: ["", Validators.required],
      }, { validators: this.checkPasswords
    })
    localStorage.removeItem('signupValues');
    this.signupForm.valueChanges.subscribe( (ans) => {
      this.cdr.detectChanges()
      this.signupValues=ans
    })
  }

  ngOnChanges(changes) {
  }

  get frm() {
    return this.signupForm.controls;
  }

  goToTarget(targetComponent){
    this.goToComponent.emit(targetComponent);
  }

  public emailChange(email):void{
    this.userService.emailExists(email).subscribe(res=>{
      if(res==true){
        this.emailExists=true;
      }
      else{
        this.emailExists=false;
      }
    })
  }


  public signUp(): void {
    this.submitted=true;
    if(this.currentStep==1){
      this.submitted=true;
      let cnt=0;
      ['fullName','password','repeatPassword','workEmail','phoneNumber']
      .forEach(element => {
        if(this.frm[element].errors!=null)cnt++;
      });
      if(cnt!=0||this.frm.password.value != this.frm.repeatPassword.value ){
        return;
      }
      else if(this.emailExists == true){
        return;
      }
      else{
        this.submitted=false;
        this.currentStep=2;
        this.startedSteps=true;
      }
    }
    if(this.currentStep==2){
      if(!this.signupForm.valid){
        return;
      }
      this.submitted=false;
      this.signupValues=this.signupForm.value;
      localStorage.setItem('signupValues',JSON.stringify(this.signupValues));
      this.goToTarget('final-step')
    }
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = group.get('password').value;
    let confirmPass = group.get('repeatPassword').value
    return pass === confirmPass ? null : { notSame: true }
  }

}

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ok } from 'assert';
import { Chart, ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Color, Label } from 'ng2-charts';
import { ScreeningResult } from 'src/app/models/dtos.model';
import { Screening } from 'src/app/models/screening.model';
import { ScreeningService } from 'src/app/services/screening.service';
import { ValidationService } from 'src/app/services/validation.service';
import { CompanyService } from '../../../../services/company.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
  selector: 'app-result-summary',
  templateUrl: './result-summary.component.html',
  styleUrls: ['./result-summary.component.scss']
})
export class ResultSummaryComponent implements OnInit {
  public screeningId:number;
  public screeningResult:any;
  public showResult:boolean=false;
  public showLoading:boolean=false
  public downloadPdf:boolean=false;
  public downloadExel:boolean=false;
  public showValidationView:boolean=false;
  public showCorrectionDialog:boolean=false;
  public showCorrectionInfo:boolean=false;
  public showScopesCharts:boolean=false;
  public LineChart:any;
  public highiestScope:any;
  public datasetColors=["#123B68","#6487A3","#98D6D4","#AEAEAE","#DDDDDD"];
  public pieChartLabels1: Label[] = [];
  public pieChartLabels2: Label[] = [];
  public pieChartLabels3: Label[] = [];
  public pieChartData1:any[] = [];
  public pieChartData2:any[] = [];
  public pieChartData3:any[] = [];
  public pieChartColors: Array<any> = [
    { 
      backgroundColor: ['#2DB6F5', '#1A6C92', '#98D6D4','#ACEEFF','#123B68','#CCCCCC']
    }
  ];
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartType: ChartType = 'pie';
  public pieChartOptions: ChartOptions; 
  public barChartOptions: ChartOptions;
  public lifeCycleLabels: Label[] ;
  public MaterialLabels: Label[] ;
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public hasRequest = false;
  public screening :Screening;
  public maxCycle:any;
  public lifeCycleImpacts: ChartDataSets[];
  public materialImpacts: ChartDataSets[];
  public screenings: Screening[];
  public scopesChartColors: Array<any> = [
    { 
      backgroundColor: ['#123B63', '#98D6D4', '#6487A3']
    }
  ];
  
  public chartColors: Array<any> = [
    {
      backgroundColor: ['#97D6D4', '#123B63', '#123B63','#123B63','#123B63','#123B63','#123B63', ]
    }
  ];

  public chartColorsCycle: Array<any> = [
    {
      backgroundColor: ['#123B63', '#123B63', '#123B63','#123B63','#123B63','#123B63','#123B63', ]
    }
  ];

    /**stacked doughnut chart config for scopes */
  public doughnutScopesLabels = ['Scope 1', 'Scope 2', 'Scope 3'];
  public doughnutScopesData = [80, 83, 94];
  public doughnutScopesType = 'doughnut';
  public legend: {   position: "top",   align: "start"};
  public scopesColors:any[]=['#123B63','#98D6D4', '#6487A3']
  /**  bar chart config for scopes */
  public barChartOptionss:any;
  public barChartLabels:any[] = [];
  public ScopeParameterChartLabels:any[] = ["Scope 1","Scope 2","Scope 3"];
  public barChartTypes = 'bar';
  public barChartLegends = true;
  public testData:any[] = [];
  public lifeCycleChartData:any[]=[];
  public ScopeParameterChartData:any[]=[];
  public showMore=[];

 public bchartoptions : ChartOptions; 
  /**stacked bar chart config for scopes */
public stackedbarChartOptions: any = {
  scaleShowVerticalLines: false,
  responsive: true,
  scales: {
          xAxes: [{
              stacked: true,
              gridLines: {
                drawOnChartArea: false,
                color:'#DDDDDD',
                zeroLineColor:'#DDDDDD'
              },
          }],
          yAxes: [{
              stacked: true,
              gridLines: {
                drawOnChartArea: false,
                color:'#DDDDDD',
                zeroLineColor:'#DDDDDD'
              },
          }],
          ticks: {
            stepSize: 1000000,
            beginAtZero: true,

          }
      }
};
  constructor(
    public activatedRoute:ActivatedRoute,
    public screeningService: ScreeningService, 
    public validationService: ValidationService,
    private companyService:CompanyService,
    private dbService: NgxIndexedDBService
    ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.screeningId= Number( params["screeningId"]);
      this.screeningService.updateState(this.screeningId,"result-summary").subscribe(res=>{});
      this.screeningService.getScreeningById(this.screeningId).subscribe(res=>{
        this.dbService.getAll('Screenings').subscribe((indexedDBResut:Array<any>) => {
          let mergeArray=indexedDBResut[0].filter(x=>x.id!=res.id);
          mergeArray.unshift(res);
          this.dbService.clear('Screenings').subscribe((status) => {
            this.dbService.add('Screenings', mergeArray)
              .subscribe((key) => {
              });
          });
        },
        error => {
            console.log(error);
        });
        this.screening=res;
        this.validationService.hasValidationRequest(res.id).subscribe(request=>{
          this.hasRequest=request.hasRequest;
        });
      });
      this.screeningService.getScreeningResult(this.screeningId).subscribe(res=>{
        this.showResult=true;
        this.screeningResult=res;
        this.lifeCycleLabels=this.screeningResult.lifeCycleImpacts.map(x=>x.cycleName);
        let maxCycleValue = Math.max(...this.screeningResult.lifeCycleImpacts.map(x=>x.impact));
        let pos = this.screeningResult.lifeCycleImpacts.findIndex(x=>x.impact===maxCycleValue);
        this.maxCycle = this.screeningResult.lifeCycleImpacts[pos];
        this.chartColorsCycle[0].backgroundColor[pos]="#97D6D4";
        this.MaterialLabels=this.screeningResult.materialImpacts.map(x=>x.stage+"-"+x.materialName+"-"+x.location);
        this.lifeCycleImpacts= [
          { data: this.screeningResult.lifeCycleImpacts.map(x=>x.impact) },
        ];
        this.materialImpacts= [
          { data: this.screeningResult.materialImpacts.map(x=>x.impact), label: 'Series A' },
        ];
        this.bchartoptions= {
          responsive: true,
          maintainAspectRatio:false,
                 scales: {
                  xAxes: [{
                      stacked:false,
                      gridLines: {
                        drawOnChartArea: false,
                        color:'#DDDDDD',
                        zeroLineColor:'#DDDDDD'
                      },
                      ticks: {
                        beginAtZero: true,
                        fontFamily:'Nunito Sans',
                        stepSize: 1300,
                        callback: function(label:any, index, labels) {
                          if (/\s/.test(label)) {
                            return label.split(" ");
                          }else{
                            return label;
                          }
                        }
                      }
                  }],
                  yAxes: [{
                    gridLines: {
                      drawOnChartArea: false,
                      color:'#DDDDDD',
                      zeroLineColor:'#DDDDDD'
                    },
                    stacked:false,
                    ticks: {
                      stepSize: 1000000,
                      beginAtZero: true,
      
                    }
                }]
              },
              tooltips: {
                titleFontFamily:'Nunito Sans',
                callbacks: {
                    label: function(tooltipItems, data) {
                        return  Number(tooltipItems.yLabel).toFixed(2) +' '+ res.unit +' CO2e';
                    }
                }
              }
        };
        this.barChartOptionss = {
          scaleShowVerticalLines: false,
          responsive: true,
          tooltips: {
            titleFontFamily:'Nunito Sans',
            callbacks: {
                label: function(tooltipItems, data) {
                    return  Number(tooltipItems.yLabel)<100?Number(tooltipItems.yLabel).toFixed(2):Number(tooltipItems.yLabel).toFixed(0) +' '+ res.unit +' CO2e';
                }
            }
          }
        };
        this.barChartOptions= {
          responsive: true,
                 scales: {
                  xAxes: [{
                      gridLines: {
                        display:false
                      },
                      ticks: {
                        autoSkip: false,
                        maxRotation: 90,
                        minRotation: 90,
                        fontFamily:'Nunito Sans',
                        stepSize: 1300,
                        callback: function(label:any, index, labels) {
                          if (/\s/.test(label)) {
                            return label.split("-");
                          }else{
                            return label;
                          }
                        }
                      }
                  }],
                  yAxes: [{
                    ticks: {
                      callback: function(label:any, index, labels) {
                        if(label>100){
                          return new Intl.NumberFormat('en-us', {maximumFractionDigits: 0}).format(label) +"";
                        }
                        else{
                          return label.toFixed(2) +"";
                        }
                      },
                      stepSize: this.maxCycle?.impact/5,
                    }
                }]
              },
              tooltips: {
                titleFontFamily:'Nunito Sans',
                callbacks: {
                    label: function(tooltipItems, data) {
                        return  Number(tooltipItems.yLabel)<100?Number(tooltipItems.yLabel).toFixed(2):Number(tooltipItems.yLabel).toFixed(0) +' '+ res.unit +' CO2e';
                    }
                }
              }
        };
        this.pieChartOptions= {
          responsive: true,
          legend:{
            display:false,
            position:'bottom',
            align:'start',
            fullWidth:false,
            labels:{
              boxWidth:15,
            }
          },
          tooltips: {
            titleFontFamily:'Nunito Sans',
            callbacks: {
                label: function(tooltipItems, data) {
                    return  Number(data.datasets[0].data[tooltipItems.index])<100?Number(data.datasets[0].data[tooltipItems.index]).toFixed(2)+' '+ res.unit +' CO2e':Number(data.datasets[0].data[tooltipItems.index]).toFixed(0) +' '+ res.unit +' CO2e';
                }
            }
          }
        };
        this.bchartoptions.scales.yAxes=[
          {
            gridLines: {
              drawOnChartArea: false,
              color:'#DDDDDD',
              zeroLineColor:'#DDDDDD'
            },
            stacked:false,
            ticks: {
              stepSize: Math.pow(10, ~~this.maxCycle?.impact.toString().length)/5,
              beginAtZero: true,

            }
        }];
        this.screeningService.getScopesResult(this.screeningId).subscribe(scopeResult=>{
          this.highiestScope = {name:scopeResult.highiestScopeName,impact:scopeResult.highiestScopeValue};
          this.doughnutScopesData=[res.scopeOne,res.scopeTwo,res.scopeThree];
          this.barChartLabels=res.lifeCycleImpacts.map(x=>x.cycleName);
          setTimeout(() => {
            this.lifeCycleChartData=[
              {data:scopeResult.scopeOneLifeCycles,label: 'Scope 1', barThickness:12, backgroundColor:this.scopesColors[0], hoverBackgroundColor:this.scopesColors[0],categoryPercentage: 0.5, barPercentage: 0.5,},
              {data:scopeResult.scopeTwoLifeCycles,label: 'Scope 2', barThickness:12, backgroundColor:this.scopesColors[1], hoverBackgroundColor:this.scopesColors[1],categoryPercentage: 0.5, barPercentage: 0.5,},
              {data:scopeResult.scopeThreeLifeCycles,label: 'Scope 3', barThickness:12, backgroundColor:this.scopesColors[2], hoverBackgroundColor:this.scopesColors[2],categoryPercentage: 0.5, barPercentage: 0.5,}
            ];
            this.pieChartData1=scopeResult.scope1Parameters.map(x=>x.impact);
            this.pieChartData2=scopeResult.scope2Parameters.map(x=>x.impact);
            this.pieChartData3=scopeResult.scope3Parameters.map(x=>x.impact);
            this.pieChartLabels1=scopeResult.scope1Parameters.map(x=>x.materialName);
            this.pieChartLabels2=scopeResult.scope2Parameters.map(x=>x.materialName);
            this.pieChartLabels3=scopeResult.scope3Parameters.map(x=>x.materialName);
          }, 1000);
          
          this.showScopesCharts=this.screening?.includeScopes;
        });
        
      });
    });
    this.validationService.hasValidationRequest(this.screeningId).subscribe(res=>{
      if(res.hasRequest==true){
        if(res.request.status=="corrected"){
          this.showCorrectionInfo=true;
        }
      }
    });
    this.calculateValidityQuality(this.screeningId);
    
  }

  public downloadSheet(event):void{
    this.showLoading=true;
    this.screeningService.getExcelResult(this.screeningId,this.screening.name).subscribe(blob => {
      this.screeningService.DownloadFile(blob, this.screening.name+".xlsx", 'application/pdf');
      this.showLoading=false;
    });
  }

  public downloadProofOfAssessment():void{
    this.showLoading=true;
    this.screeningService.getProofOfAssessment(this.screeningId,this.screening.name).subscribe(blob => {
      this.screeningService.DownloadFile(blob, this.screening.name+".pdf", 'application/pdf');
      this.showLoading=false;
    });

  }

  public showValidationModal(isShow: boolean){
    this.showValidationView = isShow;
  }

  public showCorrectionModal(event:any){
    this.showCorrectionDialog=false;
    if(event==true){
      this.validationService.notifyAdmin(this.screeningId).subscribe(res=>{

      });
    }
  }

  public downloadCertificate():void{
    this.validationService.hasValidationRequest(this.screening.id).subscribe(res=>{
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', res.request.validationCertificate);
      link.setAttribute('download', this.screening.name+`.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  public openChangesModal():void{
    this.showCorrectionDialog = true;
  }


  public dataSet:any=[];
  public top5:any= [];
  public totalRelevance=0;
  public top5relevance=0;
  public Math=Math;

  toggleTooltip(tooltip, tipcontent) {
		if (tooltip.isOpen()) {
			tooltip.close();
		} else {
			tooltip.open({ tipcontent });
		}
	}

  public treeToShow:any;
  public calculateValidityQuality(id){
    this.companyService.calculateValidityQuality(id).subscribe(ans=>{
      this.dataSet =ans;
      ans['dataSetTransperancies'].forEach(element => {
        this.takeALoop(element)
      });
      this.top5=ans.dataSetTransperancies.sort((a,b) =>  b.dataSetRelevance-a.dataSetRelevance).slice(0, 5);
      this.top5relevance=100;
    })
  }

  public takeALoop(current){ 
    this.top5.push({
      dataSetName: current['dataSetName'],
      dataSetOwner: current['dataSetOwner'],
      dataSetQuality: current['dataSetQuality'],
      dataSetValidity: current['dataSetValidity'],
      dataSetRelevance: current['dataSetRelevance'],
      dataSetType: current['dataSetType'],
    });
    this.totalRelevance+=current['dataSetRelevance'];
    current['dataSetTransperancies'].forEach(element => {
      this.takeALoop(element)
    });
  }

}

import { Component, OnInit, Output,EventEmitter, Inject, Renderer2, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
declare function reload_cdn():any;


@Component({
  selector: 'app-final-step',
  templateUrl: './final-step.component.html',
  styleUrls: ['./final-step.component.scss']
})
export class FinalStepComponent implements OnInit {
  @Output() goToComponent= new EventEmitter();
  public selected='plan-card-basic';
  public showTablets=false;
  

  constructor(
    private _renderer2: Renderer2, 
    private router: Router,
    @Inject(DOCUMENT) private _document: Document,
    private elementRef:ElementRef,
  ) { }

  ngOnInit(): void {
    reload_cdn();
  }

  // ngAfterViewInit() {
  //   var s = this._document.createElement("<script></script>");
  //   s.type = "text/javascript";
  //   s.src = "https://js.chargebee.com/v2/chargebee.js";
  //   this.elementRef.nativeElement.appendChild(s);
  // }

  public selectPlan(element){
    if(this.selected == element){
      this.selected = '';
    }else{
      this.selected = element;
    }
  }


  goToTarget(targetComponent){  
    this.goToComponent.emit(targetComponent);
  }

  public updateUserData(plan){
    let user=JSON.parse(localStorage.getItem('signupValues'));
    user.plan=plan;
    localStorage.setItem('signupValues',JSON.stringify(user));
  }
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Company } from 'src/app/models/company.model';
import { LifeCycle } from 'src/app/models/life-cycle.model';
import { Screening,ScreeningLog } from 'src/app/models/screening.model';
import { CompanyService } from 'src/app/services/company.service';
import { LifeCycleServiceService } from 'src/app/services/life-cycle-service.service';
import { ScreeningService } from 'src/app/services/screening.service';
import { UserService } from 'src/app/services/user.service'; 
import { ValidationService } from 'src/app/services/validation.service';
@Component({
  selector: 'app-screening-review',
  templateUrl: './screening-review.component.html',
  styleUrls: ['./screening-review.component.scss']
})
export class ScreeningReviewComponent implements OnInit {
  @Output() public close = new EventEmitter();

  public screeningId:number;
  public loadingMessage:string= '';
  public showLoadingMessage:Boolean= false;
  public phrases: any[] = [];
  public screeningReview:any;
  public activeCollapsible:number;
  public loading:boolean=true;
  public loadingSave:boolean=false;
  public confirmation:boolean=false;
  public inventoryApproved:boolean=false;
  public company:Company;
  public calculation=0;
  public loadingInventory:boolean=true;
  public hasRequest:boolean=false;
  public screening:Screening;
  public screening_reflow_id;
  constructor(public activatedRoute:ActivatedRoute, private lifeCycleService:LifeCycleServiceService ,private userService:UserService ,public toastrService:ToastrService ,public screeningService:ScreeningService, public companyService:CompanyService, private validationService:ValidationService) { }

  ngOnInit(): void {
    this.phrases = [
      {
        phrase: 'Collecting data from EcoInvent database',
      },
      {
        phrase: 'Aligning lifeCycle inventory with the database',
      },
      {
        phrase: 'Retrieving impact factors for your assessment from ReCiPe',
      },
      {
        phrase: 'Analysing your your impact hotspots',
      },
      {
        phrase: 'Generating your assessment summary',
      },
    ];
    this.activatedRoute.params.subscribe((params) => {
      this.screeningId= Number( params["screeningId"]);
      this.validationService.hasValidationRequest(this.screeningId).subscribe(res=>{
        this.hasRequest=res.hasRequest;
      });
      this.screeningService.updateState(this.screeningId,"review").subscribe(res=>{
        this.screeningService.getScreeningById(this.screeningId).subscribe(res=>{
          this.screeningReview=res;
          this.loading=false;
          let companyId = Number(localStorage.getItem("company_id"));
          this.companyService.retrieveCustomerCompany(companyId).subscribe(resCompany=>{
            this.company=resCompany;
          });
          this.screeningService.getInventoryById(this.screeningId).subscribe(inventory=>{
            this.screeningReview.lifeCycles=inventory;
            this.loadingInventory=false;
          });
        });
      });
    });
    this.screeningService.getScreeningById(this.screeningId).subscribe(res=>{
      this.screening_reflow_id = res.reflowId;
    });
  }
  public showdivs():void {
    var timer = 0;
      do{
        timer=timer+2000
        setTimeout(() => {
          this.loadingMessage = this.phrases[0].phrase;
        }, timer);
        timer = timer+2000
        setTimeout(() => {
          this.loadingMessage = this.phrases[1].phrase;
        },timer);
        timer = timer+2000
        setTimeout(() => {
          this.loadingMessage = this.phrases[2].phrase;
        }, timer);
        timer = timer+2000
        setTimeout(() => {
          this.loadingMessage = this.phrases[3].phrase;
        }, timer);
        timer = timer+2000
        setTimeout(() => {
          this.loadingMessage = this.phrases[4].phrase;
        }, timer);
      }while(timer<=10000)    
  }

  public next() {
    this.loadingSave=true;
    this.confirmation=false;
    this.showLoadingMessage=true;
    this.showdivs();
    setTimeout(()=>{
      this.screeningService.calculateScreeningResult(this.screeningId).subscribe(res=>{
        this.close.emit("next");
        this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(user=>{
          let screeningLog = {date:new Date(),description:"Approve Screening and start calculation", screeningId:this.screeningId};
          this.screeningService.createScreeningLog(screeningLog).subscribe(resLog=>{
          });
        });
      });
    },10000);
  }
  
  public save() {
    this.close.emit("close");
  }

  public previous() {
    this.close.emit("previous");
  }

  public closeModal(){
    this.confirmation=false;
  }

  public openModal(){
    if(this.hasRequest==true){
      if(this.inventoryApproved==true && this.loadingInventory==false){
        this.confirmation=true;
      }
      else{
        this.toastrService.warning("please approve the assessment inventory","Approval needed");
      }
    }
    else{
      if(this.inventoryApproved==true && this.loadingInventory==false){
        this.calculation = 1;
        if(this.company.reflowPoints>=1){
          this.confirmation=true;
        }
        else{
          this.toastrService.error("you don't have enough tokens to proceed the calculation","Token balance");
        }
      }
      else{
        this.toastrService.warning("please approve the assessment inventory","Approval needed");
      }
    }
  }

  public approveSetup(systemDefinition:any,screeningSetup:any):void{
    systemDefinition.className+=" show";
    screeningSetup.className="collapse";
  }

  public approveDefinition(screeningInventory:any,systemDefinition:any):void{
    screeningInventory.className+=" show";
    systemDefinition.className="collapse";
  }
  
  public approveInventory(screeningInventory:any):void{
    screeningInventory.className="collapse";
    this.inventoryApproved=true;
  }

  public editSection(sectionName:string):void{
    this.close.emit(sectionName);
  }

  public setActiveCollapsible(id){
    this.activeCollapsible!==id?this.activeCollapsible=id:this.activeCollapsible=null;
  }
}
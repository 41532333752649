import { Status } from "./status.model";
import { ComponentFile } from "./component-file.model";
import { ComponentLogFile } from "./component-file-log";
import { Vessel } from "./vesset";
import { StatusFile } from "./status-file.model";
import { Screening } from "./screening.model";

export class ComponentOverviewDto {
  public id: number;
  public owner: string;
  public reference: string;
  public type: string;
  public description: string;
  public Status: string;
  public daysInStatus: string;
  public ownerLogoUrl: string;
  public componentPicture: string;
  public recommendedHours: number;
  public tte: number;
  public ttePercent: number;
  public coSaving: number;
}

export class ComponentEditDto {
  public id: number;
  public reflowId: string;
  public ownerId: number;
  public owner: string;
  public manufacturerId: number;
  public manufacturer: string;
  public type: string;
  public companyId: number;
  public specification: string;
  public condition: string;
  public status: string;
  public warehouseId: number;
  public warehouse: string;
  public description: string;
  public serialNumber: string;
  public supplierReference: string;
  public componentPicture: string;
  public recommendedHours: number;
  public utilization: number;
  public vesselOperatorId: number;
  public voReference: string;
  public vesselId: number;
  public vessel: string;
  public location: string;
  public locationId: number;
  public velocationssel: string;
  public noX: boolean;
  public classCertificate: boolean;
  public componentFiles: ComponentFile[];
  public statusList: Status[];
}

export class UpdateComponentDto {
  public constructor(init?: Partial<UpdateComponentDto>) {
    Object.assign(this, init);
  }
  public id: number;
  public supplierReference: string;
  public componentPicture: string;
  public recommendedHours: number;
  public utilization: number;
  public componentFiles: ComponentFile[];
}

export class ComponentLogOverviewDto {
  public constructor(init?: Partial<ComponentLogOverviewDto>) {
    Object.assign(this, init);
  }
  public id: number;
  public userName: string;
  public createdAt: Date;
  public action: string;
  public componentLogFiles: ComponentLogFile[];
}

export class LocationOverviewDto {
  public id: number;
  public type: string;
  public name: string;
  public manufacturer: string;
  public serialNumber: string;
  public description: string;
  public vesselImage: string;
  public runningHours: number;
  public specification: string;
  public endPoint: string;
  public vesselId: number;
  public vessel: Vessel;
  public totalComponenent: number;
  public averageUtilization: number;
}

export class VesselOverviewDto {
  public id: number;
  public imo: number;
  public mmsi: number;
  public vesselName: string;
  public vesselType: string;
  public description: string;
  public vesselPicture: string;
  public companyId: number;
  public componentNumber: number;
  public coSaving: number;
  public tte: number;
}

export class UpdateStatusVoDto {
  public componentId: number;
  public currentStatus: string;
  public warehouse: string;
  public warehouseId: number;
  public warehouseLocation: string;
  public vessel: string;
  public vesselId: number;
  public location: string;
  public locationId: number;
  public stockReason: string;
  public voReference: string;
  public recommendHours: number;
  public utilization: number;
  public installationDate: string;
  public installationTime: string;
  public purchaseOrder: string;
  public supplier: string;
  public supplierId: number;
  public statusFiles: StatusFile[];
}

export class ExchangeComponentDto {
  public componentId: string;
  public componentReference: string;
  public componentImage: string;
  public componentType: string;
  public componentDescription: string;
  public componentStatus: string;
  public componentDaysStatu: string;
  public componentReturnCompan: string;
  public componentReturnCompanyImage: string;
  public exchangeId: string;
  public exchangeReference: string;
  public exchangeImage: string;
  public exchangeType: string;
  public exchangeDescription: string;
  public exchangeStatus: string;
  public exchangeDaysStatus: string;
  public exchangeReturnCompany: string;
  public exchangeReturnCompanyImage: string;
  public vesselImage: string;
  public vesselName: string;
  public location: string;
  public locationId: string;
}

export class ExchangeOverview {
  public id: number;
  public owner: string;
  public reference: string;
  public type: string;
  public description: string;
  public componentPicture: string;
  public Status: string;
  public daysInStatus: string;
  public installationDate: string;
}

export class ComponentFilterDto {
  public constructor(init?: Partial<ComponentFilterDto>) {
    Object.assign(this, init);
  }
  public owners: number[];
  public manufacturers: number[];
  public types: string[];
  public suppliers: number[];
  public locations: number[];
  public status: string[];
}

export class SignUpDto {
  public fullName: string;
  public password: string;
  public repeatPassword: string;
  public companyName: string;
  public workEmail: string;
  public phoneNumber: string;
  public companyType: string;
}

export class ProfilPictureDto {
  public constructor(init?: Partial<ProfilPictureDto>) {
    Object.assign(this, init);
  }
  public userId: number;
  public pictureUrl: string;
}

export class ComponentGroup {
  public groupTitle: string;
  public groups: string[];
}

export class Co2LightDto {
  public constructor(init?: Partial<Co2LightDto>) {
    Object.assign(this, init);
  }
  public vesselType: string;
  public vesselArea: string;
  public vesselAge: number;
  public componentGroup: string;
  public lifeSpan: number;
  public componentWeight: number;
  public componentScenario: string;
}

export class Co2LightResult {
  public reduction: number;
  public footprint: number;
  public saving: number;
}

export class verificationCodeDto {
  public verificationCode: number;
  public emailAddress: String;
  public password: String;
}

export class CreateScreeningDto{
  public companyId:Number;
  public folderId:Number;
}

export class Groups{
  public group:string;
  public subGroups:SubGroups[];
}

export class SubGroups{
  public subGroup:string;
  public endGroups:EndGroups[];
}

export class EndGroups{
  public endGroup:string;
  public emission:string;
  public unit:string;
  public description:string;
  public processes:Processes[];
}

export class Processes{
  public process:string;
  public emission:string;
  public unit:string;
  public description:string;
}

export class Material{
  public name:string;
  public quantity:number;
  public unit:string;
  public type:string;
  public note:string;
  public materialNumber:string; 
  public productNumber:string;
  public lifeCycleId:number;
}

export class SharedComponent{
  public name:string;
  public quantity:number;
  public unit:string;
  public type:string;
  public note:string;
  public materialNumber:string; 
  public productNumber:string;
  public lifeCycleId:number;
}

export class ScreeningResult{
  public id:number ;
  public totalCarboonFootprint:number ;
  public totalReduction:number ;
  public reductionAmount: number;
  public extendLifeSpan: number;
  public scopeOne: number;
  public scopeTwo: number;
  public scopeThree: number;
  public lifeCycleImpacts:LifeCycleImpact[]  ;
  public materialImpacts:MaterialImpact[]  ;
  public materialScopeImpacts:any[]  ;
  public screeningId: number ;
  public  screening:Screening ;
}

export class LifeCycleImpact{
  public id:number ;
  public cycleName:string ;
  public impact:number;
  public reduction:number;
}

export class MaterialImpact{
  public id:number ;
  public materialName:string ;
  public impact:number ;
  public stage:string;
  public location:string;
}

export class ChartImpact{
  public impacts:number ;
  public labels:string ;
  public colors:string ;
  
}

